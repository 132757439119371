import cx from 'classnames';
import { forwardRef, useEffect, useRef } from 'react';
import type { ComponentPropsWithoutRef, RefObject } from 'react';

import styles from './ScrollArea.module.css';
import { useComposedRefs } from '../hooks/useComposedRefs';

type DivProps = ComponentPropsWithoutRef<'div'>;

const TIMEOUT = 1000;

interface ScrollAreaProps extends DivProps {
  innerProps?: Omit<DivProps, 'children'>;
  innerRef?: RefObject<HTMLDivElement>;
}

const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      innerProps: { className: innerClassName, ...innerProps } = {},
      innerRef: innerRefFromProps,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const stateRef = useRef<{
      timeout: number | null;
      isScrolling: boolean;
    }>({
      timeout: null,
      isScrolling: false,
    });

    const compoundedInnerRef = useComposedRefs(innerRef, innerRefFromProps);

    useEffect(() => {
      if (!innerRef?.current) {
        return;
      }
      const innerEl = innerRef?.current;

      function setScrolling() {
        innerEl.dataset.scrolling = '';
      }

      function removeScrolling() {
        stateRef.current.timeout = setTimeout(() => {
          delete innerEl.dataset.scrolling;
        }, TIMEOUT);
      }

      const handleScroll = () => {
        if (stateRef.current.isScrolling) {
          return;
        }
        setScrolling();

        if (stateRef.current.timeout) {
          clearTimeout(stateRef.current.timeout);
        }

        removeScrolling();
      };

      const handleBlur = () => {
        if (stateRef.current.timeout) {
          clearTimeout(stateRef.current.timeout);
        }

        removeScrolling();
        stateRef.current.isScrolling = false;
      };

      const handleTouchStart = (e: TouchEvent) => {
        if (e.currentTarget !== e.target) {
          return;
        }
        e.stopPropagation();

        setScrolling();

        if (stateRef.current.timeout) {
          clearTimeout(stateRef.current.timeout);
        }
        stateRef.current.isScrolling = true;
      };

      const handleTouchEnd = (e: TouchEvent) => {
        e.stopPropagation();

        if (stateRef.current.timeout) {
          clearTimeout(stateRef.current.timeout);
        }
        removeScrolling();
        stateRef.current.isScrolling = false;
      };

      innerEl.addEventListener('scroll', handleScroll);
      innerEl.addEventListener('touchstart', handleTouchStart);
      innerEl.addEventListener('touchend', handleTouchEnd);
      window.addEventListener('blur', handleBlur);

      return () => {
        innerEl.removeEventListener('scroll', handleScroll);
        innerEl.removeEventListener('touchstart', handleTouchStart);
        innerEl.removeEventListener('touchend', handleTouchEnd);
        window.removeEventListener('blur', handleBlur);
      };
    }, [innerRef]);

    return (
      <div {...props} ref={ref} className={cx(styles.container, className)}>
        <div
          {...innerProps}
          ref={compoundedInnerRef}
          className={cx(styles.content, innerClassName)}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default ScrollArea;
