import cx from 'classnames';
import ScrollArea from './components/ScrollArea';

import styles from './App.module.css';
import './App.css';
import { useState } from 'react';

// Reference: https://css-tricks.com/scrollbars-on-hover/

function App() {
  const [isShrink, setIsShrink] = useState(false);

  return (
    <div>
      <h1>Scroll area</h1>
      <h2>Native scroll</h2>
      <div className={styles.native}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
          tincidunt arcu. Donec ut nunc orci. Curabitur imperdiet dictum sapien
          nec auctor. Morbi mollis gravida est, vel ullamcorper mi consectetur
          et. Fusce quis varius augue. Duis lobortis nibh nunc, vel pharetra
          turpis commodo nec. Cras ut sapien venenatis, varius lectus et,
          fringilla augue. Cras ut efficitur erat. Aenean pulvinar consectetur
          volutpat. Etiam ornare feugiat enim, eget convallis libero.
        </p>
        <p>
          Morbi pharetra tortor sed ligula iaculis, sed varius mauris
          pellentesque. Suspendisse feugiat metus aliquam lobortis pharetra.
          Curabitur in felis convallis, vestibulum lectus facilisis, euismod ex.
          Curabitur sagittis arcu turpis, non cursus erat lobortis eu. Duis
          dignissim mauris sed sodales eleifend. Etiam egestas lorem ut faucibus
          facilisis. Nullam accumsan sit amet magna ac laoreet. Etiam finibus
          tellus vitae libero tempor auctor. Ut lectus lacus, cursus eget
          ultrices nec, blandit id nunc. Nam vehicula sit amet lacus quis
          cursus. Fusce risus libero, ornare et tellus vitae, malesuada ultrices
          diam. Curabitur id lectus nulla.
        </p>
        <p>
          Integer scelerisque tincidunt velit, non fringilla lectus interdum at.
          Nam sed pellentesque tortor. Suspendisse posuere auctor viverra.
          Quisque et pulvinar erat, id vulputate erat. In malesuada enim a
          egestas mollis. Duis sem ante, mollis tincidunt nisi et, laoreet
          mattis lacus. Aenean hendrerit, lacus ac gravida pharetra, risus purus
          consequat nulla, nec volutpat sem velit non libero. Pellentesque nec
          justo ac quam posuere varius. Curabitur eros libero, hendrerit quis
          erat vel, finibus volutpat odio. Nunc sit amet scelerisque lorem, et
          convallis velit.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          malesuada, ante quis imperdiet suscipit, quam est vulputate risus, a
          gravida libero turpis pellentesque sapien. Nam ac faucibus turpis. Sed
          leo erat, eleifend sit amet ullamcorper non, pulvinar quis odio. Nulla
          in malesuada enim. Vivamus eget interdum urna. Nulla nec blandit
          lectus. Aenean vulputate dui id est auctor, non pulvinar diam
          malesuada. Ut nec nunc sodales, varius metus non, pellentesque leo.
          Vestibulum mattis imperdiet metus, at mollis quam iaculis non. Sed
          tincidunt lacus ut maximus tempus. Ut sed dapibus sem. Nam malesuada
          justo a posuere malesuada. In ut dui ac dolor hendrerit fringilla sed
          quis arcu. Mauris sed vehicula ex. Praesent eget lacus urna.
        </p>
        <p>
          Nunc auctor odio et neque porttitor scelerisque. Donec tincidunt
          semper bibendum. Aliquam id metus non risus ornare facilisis.
          Pellentesque tortor nisi, semper et mi eget, ullamcorper lobortis
          elit. Nam rutrum molestie facilisis. Quisque tristique viverra
          tristique. Donec id ornare turpis, nec ornare mauris. Suspendisse
          laoreet malesuada tellus, ac sagittis ligula pretium ut. Vivamus ac
          orci eu mi laoreet molestie vitae vitae nunc.
        </p>
      </div>
      <h2>Nested scroll</h2>
      <ScrollArea
        className={styles.outer}
        innerProps={{
          className: styles.content,
        }}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
          tincidunt arcu. Donec ut nunc orci. Curabitur imperdiet dictum sapien
          nec auctor. Morbi mollis gravida est, vel ullamcorper mi consectetur
          et. Fusce quis varius augue. Duis lobortis nibh nunc, vel pharetra
          turpis commodo nec. Cras ut sapien venenatis, varius lectus et,
          fringilla augue. Cras ut efficitur erat. Aenean pulvinar consectetur
          volutpat. Etiam ornare feugiat enim, eget convallis libero.
        </p>

        <ScrollArea
          className={styles.inner}
          innerProps={{
            className: styles.content,
          }}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
            tincidunt arcu. Donec ut nunc orci. Curabitur imperdiet dictum
            sapien nec auctor. Morbi mollis gravida est, vel ullamcorper mi
            consectetur et. Fusce quis varius augue. Duis lobortis nibh nunc,
            vel pharetra turpis commodo nec. Cras ut sapien venenatis, varius
            lectus et, fringilla augue. Cras ut efficitur erat. Aenean pulvinar
            consectetur volutpat. Etiam ornare feugiat enim, eget convallis
            libero.
          </p>
          <p>
            Morbi pharetra tortor sed ligula iaculis, sed varius mauris
            pellentesque. Suspendisse feugiat metus aliquam lobortis pharetra.
            Curabitur in felis convallis, vestibulum lectus facilisis, euismod
            ex. Curabitur sagittis arcu turpis, non cursus erat lobortis eu.
            Duis dignissim mauris sed sodales eleifend. Etiam egestas lorem ut
            faucibus facilisis. Nullam accumsan sit amet magna ac laoreet. Etiam
            finibus tellus vitae libero tempor auctor. Ut lectus lacus, cursus
            eget ultrices nec, blandit id nunc. Nam vehicula sit amet lacus quis
            cursus. Fusce risus libero, ornare et tellus vitae, malesuada
            ultrices diam. Curabitur id lectus nulla.
          </p>
          <p>
            Integer scelerisque tincidunt velit, non fringilla lectus interdum
            at. Nam sed pellentesque tortor. Suspendisse posuere auctor viverra.
            Quisque et pulvinar erat, id vulputate erat. In malesuada enim a
            egestas mollis. Duis sem ante, mollis tincidunt nisi et, laoreet
            mattis lacus. Aenean hendrerit, lacus ac gravida pharetra, risus
            purus consequat nulla, nec volutpat sem velit non libero.
            Pellentesque nec justo ac quam posuere varius. Curabitur eros
            libero, hendrerit quis erat vel, finibus volutpat odio. Nunc sit
            amet scelerisque lorem, et convallis velit.
          </p>
        </ScrollArea>
        <p>
          Morbi pharetra tortor sed ligula iaculis, sed varius mauris
          pellentesque. Suspendisse feugiat metus aliquam lobortis pharetra.
          Curabitur in felis convallis, vestibulum lectus facilisis, euismod ex.
          Curabitur sagittis arcu turpis, non cursus erat lobortis eu. Duis
          dignissim mauris sed sodales eleifend. Etiam egestas lorem ut faucibus
          facilisis. Nullam accumsan sit amet magna ac laoreet. Etiam finibus
          tellus vitae libero tempor auctor. Ut lectus lacus, cursus eget
          ultrices nec, blandit id nunc. Nam vehicula sit amet lacus quis
          cursus. Fusce risus libero, ornare et tellus vitae, malesuada ultrices
          diam. Curabitur id lectus nulla.
        </p>
        <p>
          Integer scelerisque tincidunt velit, non fringilla lectus interdum at.
          Nam sed pellentesque tortor. Suspendisse posuere auctor viverra.
          Quisque et pulvinar erat, id vulputate erat. In malesuada enim a
          egestas mollis. Duis sem ante, mollis tincidunt nisi et, laoreet
          mattis lacus. Aenean hendrerit, lacus ac gravida pharetra, risus purus
          consequat nulla, nec volutpat sem velit non libero. Pellentesque nec
          justo ac quam posuere varius. Curabitur eros libero, hendrerit quis
          erat vel, finibus volutpat odio. Nunc sit amet scelerisque lorem, et
          convallis velit.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          malesuada, ante quis imperdiet suscipit, quam est vulputate risus, a
          gravida libero turpis pellentesque sapien. Nam ac faucibus turpis. Sed
          leo erat, eleifend sit amet ullamcorper non, pulvinar quis odio. Nulla
          in malesuada enim. Vivamus eget interdum urna. Nulla nec blandit
          lectus. Aenean vulputate dui id est auctor, non pulvinar diam
          malesuada. Ut nec nunc sodales, varius metus non, pellentesque leo.
          Vestibulum mattis imperdiet metus, at mollis quam iaculis non. Sed
          tincidunt lacus ut maximus tempus. Ut sed dapibus sem. Nam malesuada
          justo a posuere malesuada. In ut dui ac dolor hendrerit fringilla sed
          quis arcu. Mauris sed vehicula ex. Praesent eget lacus urna.
        </p>
        <p>
          Nunc auctor odio et neque porttitor scelerisque. Donec tincidunt
          semper bibendum. Aliquam id metus non risus ornare facilisis.
          Pellentesque tortor nisi, semper et mi eget, ullamcorper lobortis
          elit. Nam rutrum molestie facilisis. Quisque tristique viverra
          tristique. Donec id ornare turpis, nec ornare mauris. Suspendisse
          laoreet malesuada tellus, ac sagittis ligula pretium ut. Vivamus ac
          orci eu mi laoreet molestie vitae vitae nunc.
        </p>
      </ScrollArea>
      <h2>Layout</h2>
      <button onClick={() => setIsShrink((s) => !s)}>
        {isShrink ? 'Expand' : 'Shrink'}
      </button>
      <ScrollArea
        className={cx(styles.shrinkable, isShrink && styles.shrink)}
        innerProps={{
          className: styles.content,
        }}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
          tincidunt arcu. Donec ut nunc orci. Curabitur imperdiet dictum sapien
          nec auctor. Morbi mollis gravida est, vel ullamcorper mi consectetur
          et. Fusce quis varius augue. Duis lobortis nibh nunc, vel pharetra
          turpis commodo nec. Cras ut sapien venenatis, varius lectus et,
          fringilla augue. Cras ut efficitur erat. Aenean pulvinar consectetur
          volutpat. Etiam ornare feugiat enim, eget convallis libero.
        </p>
      </ScrollArea>
      <h2>Having focusable child</h2>
      <ScrollArea
        className={styles.focusable}
        innerProps={{
          className: styles.content,
        }}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
          tincidunt arcu. Donec ut nunc orci. Curabitur imperdiet dictum sapien
          nec auctor. Morbi mollis gravida est, vel ullamcorper mi consectetur
          et. Fusce quis varius augue. Duis lobortis nibh nunc, vel pharetra
          turpis commodo nec. Cras ut sapien venenatis, varius lectus et,
          fringilla augue. Cras ut efficitur erat. Aenean pulvinar consectetur
          volutpat. Etiam ornare feugiat enim, eget convallis libero.
        </p>
        <div>
          <input />
        </div>
        <div>
          <button>Button</button>
        </div>
        <p>
          Morbi pharetra tortor sed ligula iaculis, sed varius mauris
          pellentesque. Suspendisse feugiat metus aliquam lobortis pharetra.
          Curabitur in felis convallis, vestibulum lectus facilisis, euismod ex.
          Curabitur sagittis arcu turpis, non cursus erat lobortis eu. Duis
          dignissim mauris sed sodales eleifend. Etiam egestas lorem ut faucibus
          facilisis. Nullam accumsan sit amet magna ac laoreet. Etiam finibus
          tellus vitae libero tempor auctor. Ut lectus lacus, cursus eget
          ultrices nec, blandit id nunc. Nam vehicula sit amet lacus quis
          cursus. Fusce risus libero, ornare et tellus vitae, malesuada ultrices
          diam. Curabitur id lectus nulla.
        </p>
        <p>
          Integer scelerisque tincidunt velit, non fringilla lectus interdum at.
          Nam sed pellentesque tortor. Suspendisse posuere auctor viverra.
          Quisque et pulvinar erat, id vulputate erat. In malesuada enim a
          egestas mollis. Duis sem ante, mollis tincidunt nisi et, laoreet
          mattis lacus. Aenean hendrerit, lacus ac gravida pharetra, risus purus
          consequat nulla, nec volutpat sem velit non libero. Pellentesque nec
          justo ac quam posuere varius. Curabitur eros libero, hendrerit quis
          erat vel, finibus volutpat odio. Nunc sit amet scelerisque lorem, et
          convallis velit.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          malesuada, ante quis imperdiet suscipit, quam est vulputate risus, a
          gravida libero turpis pellentesque sapien. Nam ac faucibus turpis. Sed
          leo erat, eleifend sit amet ullamcorper non, pulvinar quis odio. Nulla
          in malesuada enim. Vivamus eget interdum urna. Nulla nec blandit
          lectus. Aenean vulputate dui id est auctor, non pulvinar diam
          malesuada. Ut nec nunc sodales, varius metus non, pellentesque leo.
          Vestibulum mattis imperdiet metus, at mollis quam iaculis non. Sed
          tincidunt lacus ut maximus tempus. Ut sed dapibus sem. Nam malesuada
          justo a posuere malesuada. In ut dui ac dolor hendrerit fringilla sed
          quis arcu. Mauris sed vehicula ex. Praesent eget lacus urna.
        </p>
        <p>
          Nunc auctor odio et neque porttitor scelerisque. Donec tincidunt
          semper bibendum. Aliquam id metus non risus ornare facilisis.
          Pellentesque tortor nisi, semper et mi eget, ullamcorper lobortis
          elit. Nam rutrum molestie facilisis. Quisque tristique viverra
          tristique. Donec id ornare turpis, nec ornare mauris. Suspendisse
          laoreet malesuada tellus, ac sagittis ligula pretium ut. Vivamus ac
          orci eu mi laoreet molestie vitae vitae nunc.
        </p>
      </ScrollArea>
    </div>
  );
}

export default App;
